import Header from "@/components/AutoPilotLandingPage/Header";
import Stats from "@/components/AutoPilotLandingPage/Stats";
import Triggers from "@/components/AutoPilotLandingPage/Triggers";

const AutoPilotLandingPage = () => {
  return (
    <div className="flex flex-col">
      <Header />
      <Stats />
      <Triggers />
    </div>
  );
};

export default AutoPilotLandingPage;
