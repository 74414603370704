import {
  Box,
  Text,
  Flex,
  Input,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Link,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { useFilterManager, Filter } from "./useFilterManager";
import { SaveSearchModal } from "./SaveFilters";
import { useSearchLeadsStore } from "../store";
import { useTabController } from "@/components/Common/TabController";
import { calculateAppliedFiltersCount } from "../utils";
import Icons from "@/components/Icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const SavedSearches = () => {
  const { setActiveTabIndex } = useTabController();
  const { list, remove } = useFilterManager();
  const disclosure = useDisclosure();
  const [currentFilter, setCurrentFilter] = useState<Filter | null>(null);
  const [filter, setFilter] = useState("");
  const updateState = useSearchLeadsStore((state) => state.updateState);

  const savedSearches = list();

  const filteredSearches = savedSearches.filter((search) =>
    search.name?.toLowerCase().includes(filter.toLowerCase()),
  );

  const handleDuplicate = (search: Filter) => {
    const duplicatedFilter = {
      ...search,
      name: `Copy of ${search.name}`,
      savedDate: new Date().toISOString(),
    };
    setCurrentFilter(duplicatedFilter);
    disclosure.onOpen();
  };

  const handleDelete = (index: number) => {
    remove(index);
  };

  const handleApplyFilter = (filter: Filter) => {
    updateState({ ...filter, applyFilterData: true });
    setActiveTabIndex(0);
  };

  const getCount = (filter: Filter) => {
    if (!filter) return 0;
    const count = calculateAppliedFiltersCount(filter.sideBarInput);
    return `(${count}) ${count === 1 ? "filter" : "filters"} applied`;
  };

  return (
    <Box>
      <Flex align="center" mb={4}>
        <Input
          variant={"flushed"}
          size={"sm"}
          placeholder="Find saved search..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          mr={2}
        />
        <IconButton size={"sm"} icon={<Icons.FaSearch />} aria-label="Search" />
      </Flex>

      {filteredSearches.map((search, index) => (
        <Flex
          key={index}
          justify="space-between"
          align="center"
          py={2}
          borderBottom="1px solid #e2e8f0"
        >
          <Flex direction="column" align="flex-start">
            <Link href="#" onClick={() => handleApplyFilter(search)}>
              <Icons.FilterIcon
                className="inline"
                color="gray.500"
                style={{ marginRight: "5px" }}
              />

              {search.name}
            </Link>
            <Flex align="center">
              <Text fontSize="xs" color="gray.500">
                {`Saved ${dayjs(search.savedDate).fromNow()}`}
              </Text>
            </Flex>
          </Flex>

          <Flex align="center">
            <Text textAlign={"right"} fontSize="sm" color="gray.500" mr={2}>
              {getCount(search)}
            </Text>

            <Menu>
              <MenuButton
                as={IconButton}
                icon={<Icons.BiDotsHorizontalRounded />}
                variant="ghost"
              />
              <MenuList>
                <MenuItem
                  icon={<Icons.FiCopy />}
                  onClick={() => handleDuplicate(search)}
                >
                  Duplicate
                </MenuItem>
                <MenuItem
                  icon={<Icons.FiTrash2 />}
                  onClick={() => handleDelete(index)}
                  color="red.500"
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      ))}

      {filteredSearches.length === 0 && (
        <Box className="flex size-full flex-col items-center justify-center rounded-[20px] bg-gray px-[20px] py-[50px]">
          <Text className="text-[gray]">No saved searches</Text>
          <Button
            className="mt-[10em]"
            onClick={() => setActiveTabIndex(0)}
            variant="outline"
            colorScheme="purple"
            leftIcon={<Icons.SearchIcon />}
          >
            Create new search
          </Button>
        </Box>
      )}
      {currentFilter && (
        <SaveSearchModal
          currentFilter={currentFilter}
          disclosure={disclosure}
        />
      )}
    </Box>
  );
};

export const SavedSearchesUI = () => {
  return (
    <Box p={4}>
      <SavedSearches />
    </Box>
  );
};

export default SavedSearchesUI;
