import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import Icons from "../Icons";
import SearchImg from "@/assets/svgs/search.svg";
import { useModalStore } from "@/stores/modal.store";

const PricingPopup = ({
  setShowPopup,
}: {
  setShowPopup: Dispatch<SetStateAction<boolean>>;
}) => {
  const updateModalState = useModalStore((state) => state.updateState);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 !bg-white p-4 rounded-lg shadow-xl
      transition-opacity duration-500 ease-in-out ${isVisible ? "opacity-100" : "opacity-0"}`}
    >
      <div className="mb-2 flex flex-col items-center justify-center gap-2">
        <Icons.CloseIcon
          className="fixed right-4 top-3 cursor-pointer text-[20px]"
          onClick={() => setShowPopup(false)}
        />
        <img src={SearchImg} alt="" className="mb-4" height={200} width={200} />
        <p className="text-[20px] text-black">Looking for more leads?</p>
        <p className="text-[14px] text-[#373F51]">
          Your plan only allows you to access 25 leads
        </p>
        <Button
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={`!w-full mt-4 !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
          onClick={() => {
            updateModalState({
              isOpenModal: true,
              modalData: { modalType: "pricing" },
            });
          }}
        >
          View pricing plans
        </Button>
      </div>
    </div>
  );
};

export default PricingPopup;
