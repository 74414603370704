import {
  Button,
  ButtonGroup,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { countries } from "countries-list";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import AccordionButton from "../Common/AccordionButton";
import MultiSelectDropdown from "../Common/MultiSelectDropdown";
import PersanaInput from "../Common/PersanaInput";
import SearchInputAccordion from "../Common/SearchInputAccordion";

import CompanyIcon from "@/assets/svgs/people-search-company.svg";
import JobTitleIcon from "@/assets/svgs/people-search-job-title.svg";
import LocationIcon from "@/assets/svgs/people-search-location.svg";
import NameIcon from "@/assets/svgs/people-search-name.svg";
import { useSearchLeadsStore } from "@/components/PeopleSearch/store.ts";
import leadService from "@/services/leads.service";

import { SearchCompanyType } from "@/types/leads.types";
import {
  convertSearchPeoplePayload,
  extractNameAndContinent,
  filterValidObjKeys,
  generateId,
  handlePushToArray,
} from "@/utils";
import { apolloCompanySize, jobTitles } from "@/utils/constants";
import { usePeopleSearch } from "./hooks/usePeopleSearch";
import Icons from "../Icons";
import { SaveCurrentFilter } from "./components/SaveFilters";
import { ControlledTabs } from "../Common/TabController";
import SavedSearchesUI from "./components/SavedFilters";
import { calculateAppliedFiltersCount } from "./utils";
import { userStore } from "@/stores/user.store";
import Countries from "../Leads/FindCompanies/Countries";
import CompanyNameSearches from "../Common/Searches/CompanyNameSearch";

interface StaticType {
  name: string;
  value?: string;
}

interface OptionType {
  label: string;
  value: string;
}

interface InputtedData {
  search_text: string;
  person_titles: OptionType[];
  person_not_titles: OptionType[];
  person_past_titles: OptionType[];
  person_seniorities: StaticType[];
  q_person_name: string;
  organization_ids: any; // assuming ids are numbers
  not_organization_ids: any; // assuming ids are numbers
  person_past_organization_ids: any; // assuming ids are numbers
  in_house_organizations: OptionType[];
  in_house_organizations_not: OptionType[];
  in_house_organizations_past: OptionType[];
  organization_industry_tag_ids: StaticType[]; // assuming ids are numbers
  organization_num_employees_ranges: StaticType[]; // ["20001,5000"]
  q_organization_keyword_tags: StaticType[]; // array of company tags
  q_not_organization_keyword_tags: StaticType[]; // array of company tags
  person_locations: OptionType[]; // array of locations
  person_not_locations: OptionType[]; // array of locations
  person_location_name: string; // string of zip (if location radius selected)
  person_location_radius: string; // string of radius (if location radius selected)
  // contact_email_status: string[]; // array of email statuses
  // extrapolated_email_confidence: string[]; // ["75to85"] array of email confidences
}

const SidebarComponent = ({
  isForAutoPilot = false,
}: {
  isForAutoPilot?: boolean;
}) => {
  const toast = useToast();
  let timer: NodeJS.Timeout;
  const [isUseMixRank, setIsUseMixRank] = useState(false);
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const saveFilterModalDisclosure = useDisclosure();
  const inputtedData = useSearchLeadsStore((state) => state.sideBarInput);
  const valueLabels = useSearchLeadsStore((state) => state.valueLabels);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const setInputtedData = (data: InputtedData) => {
    updateLeadState({ sideBarInput: data });
  };
  const isSearching = useSearchLeadsStore((state) => state.isSearching);
  const totalFilters = useMemo(
    () => calculateAppliedFiltersCount(inputtedData),
    [inputtedData],
  );
  const { mutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: SearchCompanyType }) =>
      leadService.searchCompany(payload),
  });
  const currentUser = userStore((state) => state.currentUser);

  const { mutateAsync: searchPeopleMutateAsync } = usePeopleSearch();
  const _peopleSearchPayload = useSearchLeadsStore(
    (state) => state.peopleSearchPayload,
  );
  const applyFilterData = useSearchLeadsStore((state) => state.applyFilterData);
  const id = useSearchLeadsStore((state) => state.instanceId);
  const genId = (field: string) => `${id}-${field}`;

  const handleCompanySearch = async (companyName: string) => {
    // Clear previous timer if it exists
    clearTimeout(timer);

    // Set a new timer to call the API after 0.5s of user inactivity
    timer = setTimeout(async () => {
      await mutateAsync(
        {
          payload: {
            q_organization_name: companyName,
            per_page: 50,
          },
        },
        {
          onSuccess: (response) => {
            // Update the state
            setFetchedCompanies(response?.data.companies || []);
            updateLeadState({
              valueLabels: {
                ...valueLabels,
                ...Object.fromEntries(
                  (response?.data.companies || []).map((company: any) => [
                    company?.organization_id ??
                      company?.id ??
                      company?.owned_by_organization_id,
                    company?.name,
                  ]),
                ),
              },
            });
          },
          onError: (error) => {
            console.log(error);
          },
        },
      );
    }, 500);
  };

  const handleSearch = useCallback(
    async (inputtedData: InputtedData) => {
      const convertedInputtedData = convertSearchPeoplePayload(inputtedData);
      const validInputtedData = filterValidObjKeys(convertedInputtedData);

      if (Object.keys(validInputtedData || {}).length === 0) {
        toast({
          title: "No data to search",
          description: "Please input some data to search",
          status: "error",
          isClosable: true,
          duration: 2000,
          position: "top-right",
        });
        return;
      }

      updateLeadState({
        isSearching: true,
        valueLabels: {
          ...valueLabels,
          ...Object.fromEntries(
            fetchedCompanies.map((company: any) => [
              company?.organization_id ??
                company.owned_by_organization_id ??
                company?.id,
              company.name,
            ]),
          ),
        },
      });
      await searchPeopleMutateAsync({
        payload: validInputtedData,
        isUseMixrank: isUseMixRank,
      });
    },
    [
      searchPeopleMutateAsync,
      updateLeadState,
      toast,
      fetchedCompanies,
      valueLabels,
      isUseMixRank,
    ],
  );
  useEffect(() => {
    if (!applyFilterData) return;
    handleSearch(inputtedData);
    updateLeadState({ applyFilterData: false });
  }, [applyFilterData, inputtedData, handleSearch]);

  useEffect(() => {
    const peopleSearchPayload =
      window.peopleSearchPayload ?? _peopleSearchPayload;
    if (!peopleSearchPayload) return;
    try {
      let newInputData = {
        search_text: "",
        person_titles: [],
        person_not_titles: [],
        person_past_titles: [],
        person_seniorities: [],
        q_person_name: "",
        organization_ids: [], // array of company ids
        not_organization_ids: [], // array of company ids
        person_past_organization_ids: [], // array of company ids
        organization_industry_tag_ids: [], // array of industry ids
        organization_num_employees_ranges: [], // array of employee range ["20001,5000"],
        q_organization_keyword_tags: [], // array of company tags
        q_not_organization_keyword_tags: [], // array of company tags
        person_locations: [], // array of locations
        person_not_locations: [], // array of locations
        person_location_name: "", // string of zip (if location radius selected)
        person_location_radius: "", // string of radius (if location radius selected)
      } as any;
      const allFetchedCompanies = _.uniqBy(
        [
          ...fetchedCompanies,
          ...(peopleSearchPayload.fetchedCompanies || []),
          ...(peopleSearchPayload.excludeFetchedCompanies || []),
          ...(peopleSearchPayload.pastFetchedCompanies || []),
        ],
        (company: any) =>
          company?.organization_id ??
          company?.owned_by_organization_id ??
          company?.id,
      );
      if (allFetchedCompanies.length > 0) {
        setFetchedCompanies(allFetchedCompanies);

        newInputData = {
          ...newInputData,
          organization_ids:
            peopleSearchPayload.fetchedCompanies?.slice(0, 1) || [],
          not_organization_ids:
            peopleSearchPayload.excludeFetchedCompanies?.slice(0, 1) || [],
          person_past_organization_ids:
            peopleSearchPayload.pastFetchedCompanies?.slice(0, 1) || [],
        };
      }

      Object.entries(peopleSearchPayload).forEach(([key, value]: any) => {
        if (
          [
            "organization_num_employees_ranges",
            "q_organization_keyword_tags",
            "q_not_organization_keyword_tags",
          ].includes(key)
        ) {
          newInputData[key] =
            value instanceof Array
              ? value?.map((item: any) => ({
                  name: item.replace(",", "-"),
                }))
              : typeof value === "string"
                ? [value]
                : [];
        } else {
          newInputData[key] =
            value instanceof Array
              ? value?.map((item: any) => ({
                  label: item,
                  value: item,
                }))
              : value;
        }
      });
      const newState = {
        ...newInputData,
      };
      setInputtedData(newState);
      handleSearch(newState);

      window.peopleSearchPayload = null;
      updateLeadState({
        peopleSearchPayload: null,
        appliedPeopleSearchPayload: peopleSearchPayload,
        valueLabels: {
          ...valueLabels,
          ...Object.fromEntries(
            allFetchedCompanies.map((company: any) => [
              company?.organization_id || company?.id,
              company?.name,
            ]),
          ),
        },
      });
    } catch (error) {
      console.error(error, "error in sidebar", _peopleSearchPayload);
      toast({
        title: "Error",
        description: "An error occurred",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    }
  }, [handleSearch, _peopleSearchPayload]);

  return (
    <div className=" flex size-full h-full max-h-screen flex-col justify-between">
      <div
        className={`grow space-y-4 overflow-y-auto bg-white px-2 py-4 ${isForAutoPilot ? "h-full" : "h-[5rem]"}`}
      >
        <>
          {currentUser?.role?.toLocaleLowerCase() === "admin" &&
          !isForAutoPilot ? (
            <div className="flex space-x-2">
              <Switch
                isChecked={isUseMixRank}
                colorScheme="purple"
                onChange={() => {
                  setIsUseMixRank(!isUseMixRank);
                }}
              />
              <label
                className="font-title font-semibold text-sm"
                htmlFor="people-search-use-mixrank"
              >
                Use MixRank
              </label>
            </div>
          ) : null}
          {/* <FilterComponent className="px-2" /> */}
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={JobTitleIcon} />}>
                Job Titles
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <div id={genId("person_titles")}>
                <p className="sticky mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Job Titles to include
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. CEO, CTO"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_titles}
                />
              </div>
              <div id={genId("person_not_titles")}>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Job Titles to exclude
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. Intern, Manager"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_not_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_not_titles}
                />
              </div>
              <div id={genId("person_past_titles")}>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Past Job Titles to include
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. Intern, Manager"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_past_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_past_titles}
                />
              </div>
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={CompanyIcon} />}>
                Company
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              {isUseMixRank ? (
                <>
                  <CompanyNameSearches
                    label="Companies to include"
                    onChange={(value) => {
                      handlePushToArray(
                        { name: "in_house_organizations", value },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                    value={inputtedData?.in_house_organizations}
                  />
                  <CompanyNameSearches
                    label="Companies to exclude"
                    onChange={(value) => {
                      handlePushToArray(
                        { name: "in_house_organizations_not", value },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                    value={inputtedData?.in_house_organizations_not}
                  />
                  <CompanyNameSearches
                    label="Past Companies to include"
                    onChange={(value) => {
                      handlePushToArray(
                        { name: "in_house_organizations_past", value },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                    value={inputtedData?.in_house_organizations_past}
                  />
                </>
              ) : (
                <>
                  <PersanaInput
                    id={genId("organization_ids")}
                    value={inputtedData?.organization_ids}
                    placeholder={"e.g. Google, Facebook, Apple"}
                    name={"organization_ids"}
                    label={"Companies to include"}
                    showDropdown
                    isDynamic
                    arrayOfValues={fetchedCompanies}
                    onChange={(target) => {
                      handleCompanySearch(target.value);
                    }}
                    onClick={(target) => {
                      console.log("target", target);

                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    id={genId("not_organization_ids")}
                    value={inputtedData?.not_organization_ids}
                    placeholder={"e.g. Google, Facebook, Apple"}
                    name={"not_organization_ids"}
                    label={"Companies to exclude"}
                    showDropdown
                    isDynamic
                    arrayOfValues={fetchedCompanies}
                    onChange={(target) => {
                      handleCompanySearch(target.value);
                    }}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    id={genId("person_past_organization_ids")}
                    value={inputtedData?.person_past_organization_ids}
                    placeholder={"e.g. Google, Facebook, Apple"}
                    name={"person_past_organization_ids"}
                    label={"Past Companies to include"}
                    showDropdown
                    arrayOfValues={fetchedCompanies}
                    onChange={(target) => {
                      handleCompanySearch(target.value);
                    }}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                </>
              )}
              {!isUseMixRank && (
                <PersanaInput
                  id={genId("organization_num_employees_ranges")}
                  value={inputtedData?.organization_num_employees_ranges}
                  placeholder={"e.g. 11-20 , 101-200"}
                  name={"organization_num_employees_ranges"}
                  label={"Number of Employees"}
                  showDropdown
                  disabledCustomSelect
                  arrayOfValues={apolloCompanySize}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
              )}
              <PersanaInput
                id={genId("q_organization_keyword_tags")}
                value={inputtedData?.q_organization_keyword_tags}
                placeholder={"e.g. Cloud, AWS"}
                name={"q_organization_keyword_tags"}
                label={"Keywords to Include"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              <PersanaInput
                id={genId("q_not_organization_keyword_tags")}
                value={inputtedData?.q_not_organization_keyword_tags}
                placeholder={"e.g. Cloud, AWS"}
                name={"q_not_organization_keyword_tags"}
                label={"Keywords to exclude"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={LocationIcon} />}>
                Location
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <div id={genId("person_locations")}>
                {isUseMixRank ? (
                  <Countries
                    label="Location to include"
                    value={inputtedData?.person_locations}
                    onChange={(selectedValues) => {
                      handlePushToArray(
                        { name: "person_locations", value: selectedValues },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                  />
                ) : (
                  <>
                    <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                      City / State / Country or Any Location
                    </p>
                    <MultiSelectDropdown
                      placeholder="e.g. San Francisco, United States"
                      options={extractNameAndContinent(countries)}
                      onChange={(selectedValues) => {
                        handlePushToArray(
                          { name: "person_locations", value: selectedValues },
                          inputtedData,
                          setInputtedData,
                        );
                      }}
                      isCreateAble
                      value={inputtedData?.person_locations}
                    />
                  </>
                )}
              </div>
              <div id={genId("person_not_locations")}>
                {isUseMixRank ? (
                  <Countries
                    label="Location to exclude"
                    value={inputtedData?.person_not_locations}
                    onChange={(selectedValues) => {
                      handlePushToArray(
                        { name: "person_not_locations", value: selectedValues },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                  />
                ) : (
                  <>
                    <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                      Location to exclude
                    </p>
                    <MultiSelectDropdown
                      placeholder="e.g. San Francisco, United States"
                      options={extractNameAndContinent(countries)}
                      onChange={(selectedValues) => {
                        handlePushToArray(
                          {
                            name: "person_not_locations",
                            value: selectedValues,
                          },
                          inputtedData,
                          setInputtedData,
                        );
                      }}
                      isCreateAble
                      value={inputtedData?.person_not_locations}
                    />
                  </>
                )}
              </div>
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={NameIcon} />}>
                Name
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <PersanaInput
                id={genId("q_person_name")}
                value={inputtedData?.q_person_name}
                placeholder={"e.g. John Doe"}
                name={"q_person_name"}
                label={"Person Name"}
                onChange={(e) => {
                  setInputtedData({
                    ...inputtedData,
                    q_person_name: e.target.value,
                  });
                }}
              />
            </div>
          </SearchInputAccordion>

          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<Icons.SearchIcon />}>
                Keyword Search
              </AccordionButton>
            }
          >
            <InputGroup id={genId("q_keywords")} className="rounded-md">
              <InputLeftElement pointerEvents="none">
                <Icons.SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                rounded="md"
                placeholder="Search People..."
                value={inputtedData.search_text}
                onChange={(e) => {
                  handlePushToArray(
                    { name: "search_text", value: e.target.value },
                    inputtedData,
                    setInputtedData,
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(inputtedData);
                  }
                }}
              />
            </InputGroup>
          </SearchInputAccordion>
        </>
      </div>
      {!isForAutoPilot && (
        <ButtonGroup className="!bg-white p-4 !font-title">
          <Button
            isDisabled={totalFilters <= 0}
            isLoading={isSearching}
            id={generateId("persana-search-button")}
            loadingText="Searching"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            className={`!w-full !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
            onClick={() => {
              handleSearch(inputtedData);
            }}
          >
            Search
          </Button>
          <IconButton
            colorScheme="gray"
            isDisabled={totalFilters <= 0}
            aria-label="Save Filters"
            icon={<Icons.SaveIcon />}
            onClick={() => {
              saveFilterModalDisclosure.onOpen();
            }}
          />
        </ButtonGroup>
      )}
      <SaveCurrentFilter disclosure={saveFilterModalDisclosure} />
    </div>
  );
};
const Sidebar = ({ isForAutoPilot }: { isForAutoPilot?: boolean }) => {
  return (
    <ControlledTabs size={"sm"} className="size-full bg-white">
      <TabList borderBottom="1px solid #F0EFF1">
        <Tab> Search</Tab>
        {!isForAutoPilot && <Tab> Saved Search</Tab>}
      </TabList>
      <TabPanels className="size-full">
        <TabPanel className="h-full !p-0">
          <SidebarComponent isForAutoPilot={isForAutoPilot} />
        </TabPanel>
        <TabPanel>
          <SavedSearchesUI />
        </TabPanel>
      </TabPanels>
    </ControlledTabs>
  );
};
export default Sidebar;
