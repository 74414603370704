import LucyProfile from "@/assets/images/lucy.png";
const Header = () => {
  return (
    <div className="flex items-center justify-center gap-2 px-[32px] py-[16px]">
      <img src={LucyProfile} />
      <p className="text-[20px] font-[600] text-[#14171F]">
        Hi, I’m Lucy, your AI SDR. How can I help?
      </p>
    </div>
  );
};

export default Header;
