import { useNavigate } from "@tanstack/react-router";
import JobChangeIcon from "@/assets/svgs/auto-pilot-find-jobs.svg";
import HiringTrendIcon from "@/assets/svgs/auto-pilot-find-hiring.svg";
import FindLeadsIcon from "@/assets/svgs/auto-pilot-find-leads.svg";
import TriggerIcon from "@/assets/svgs/auto-pilot-trigger.svg";

interface ActionItem {
  id: string;
  title: string;
  description: string;
  icon: string;
  isDisabled: boolean;
  onClick: () => void;
}

const Triggers = () => {
  const navigate = useNavigate();

  const allActions: ActionItem[] = [
    {
      id: "NewLeads",
      title: "Find new leads",
      description: "Find new leads that fit your Ideal Customer Profile (ICP).",
      icon: FindLeadsIcon,
      isDisabled: false,
      onClick: () => navigate({ to: "/auto-pilot/workflow" }),
    },
    {
      id: "JobTracking",
      title: "Find job changes",
      description: "Easily track & monitor career changes among your clients.",
      icon: JobChangeIcon,
      isDisabled: false,
      onClick: () => navigate({ to: "/account-monitoring" }),
    },
    {
      id: "HiringTrend",
      title: "Find hiring trends",
      description:
        "Track hiring trends in your customer base and target market.",
      icon: HiringTrendIcon,
      isDisabled: false,
      onClick: () => navigate({ to: "/account-monitoring" }),
    },
  ];

  return (
    <div className="mx-6">
      <div className="flex items-center justify-center gap-2 mb-4">
        <img src={TriggerIcon} />
        <p className="text-black text-lg font-medium">Triggers</p>
      </div>
      <div className="flex items-center justify-center flex-wrap gap-4">
        {allActions.map((action) => (
          <div
            key={action.id}
            className="`w-full rounded-lg bg-white border border-[#D0D4DC]"
          >
            <div className="flex flex-col p-4 gap-3">
              <div className="w-full flex items-center justify-between">
                <img src={action.icon} alt={action.title} className="w-8 h-8" />
                <div className="px-[12px] py-[8px] bg-[#F5F3FF] text-[#5024DC] text-[14px] font-medium rounded-[10px]">
                  Auto Pilot
                </div>
              </div>
              <div className="my-2">
                <p className="text-[18px] font-medium text-[#0F0D1C]">
                  {action.title}
                </p>
                <p className="text-[14px] font-normal text-[#0F0D1C] mt-1">
                  {action.description}
                </p>
              </div>
            </div>
            <div
              className="cursor-pointer bg-[#F5F3FF] px-4 py-3 rounded-b-lg border-t border-gray-300 flex items-center justify-center"
              onClick={action.onClick}
              role="button"
              aria-disabled={action.isDisabled}
            >
              <p className="text-sm text-gray-800 font-semibold">Get Started</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Triggers;
