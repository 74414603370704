import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import SelectableTextEditor from "@/components/Enrichment/Common/SelectableTextEditor";
import { usePresets } from "@/hooks/usePresets";
import { findPlaceholderValues } from "@/lib/utils";
import { usePresetsStore } from "@/stores/presets.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";

interface Props {
  personalization: any;
  selectedPreference: string;
  setPersonalization: (val: any) => void;
}

const PersonalizationPrompt = ({
  selectedPreference,
  personalization,
  setPersonalization,
}: Props) => {
  const updateState = usePresetsStore((state) => state.updateState);
  const { data: presetData } = usePresets("ai-draft-email");
  const [selectedColumns, setSelectedColumns] = useState<
    {
      placeholderValue: string;
      value: TSelectedColumnOption;
    }[]
  >([]);
  const [selectedPreset, setSelectedPreset] = useState<any>();

  const placeholderValue = useMemo(() => {
    if (!selectedPreset) return [];
    const promptPlaceHolder = findPlaceholderValues(selectedPreset.prompt);
    return [...new Set([...promptPlaceHolder])];
  }, [selectedPreset]);

  useEffect(() => {
    const newSelectedPreset =
      presetData?.find((item) => item.name === selectedPreference) || null;
    setSelectedPreset(newSelectedPreset);
  }, [presetData, selectedPreference]);

  useEffect(() => {
    let modifiedPrompts = selectedPreset?.prompt || "";

    selectedColumns.forEach((item) => {
      const placeholder = `{{${item.placeholderValue}}}`;
      if (modifiedPrompts.includes(placeholder)) {
        modifiedPrompts = modifiedPrompts.replace(
          placeholder,
          `{{${item.value.key}}}`,
        );
      }
    });
    setPersonalization(modifiedPrompts);
  }, [selectedColumns, selectedPreset, setPersonalization]);

  return (
    <VStack align="stretch" spacing={3}>
      <p className="text-sm italic text-[#4A5264]">
        {`Note:  In this step, we will run the below prompt to add the AI personalization for each lead. Map the corresponding columns.`}
      </p>

      {placeholderValue.map((value, index) => {
        return (
          <div className="text-sm" key={`${value}_${index}`}>
            <h4 className="pb-0.5 font-semibold text-[#1d2026]">{value}</h4>
            <SelectColumnDropdown
              selectedOption={
                selectedColumns.find((col) => col.placeholderValue === value)
                  ?.value || null
              }
              setSelectedOption={(selectedColumn: any) => {
                setSelectedColumns((prevState) => {
                  const matchedColumn = prevState.find(
                    (col) => col.placeholderValue === value,
                  );
                  if (matchedColumn) {
                    matchedColumn.value =
                      selectedColumn as TSelectedColumnOption;
                    return [...prevState];
                  } else {
                    return [
                      ...prevState,
                      {
                        placeholderValue: value,
                        value: selectedColumn,
                      },
                    ];
                  }
                });
              }}
            />
          </div>
        );
      })}

      <HStack justify="space-between">
        <Text fontSize="sm" fontWeight="bold">
          AI Personalization
        </Text>
        <Button
          onClick={() =>
            updateState({
              isOpenPresetsModal: true,
              presetsModalData: { modalType: "ai-draft-email" },
            })
          }
          size="sm"
          colorScheme="blue"
          variant="outline"
          borderRadius="md"
          px={4}
          py={1}
        >
          Change Template
        </Button>
      </HStack>

      <SelectableTextEditor
        title=""
        placeholder="Subject Text, type / for commands"
        setText={(value) => setPersonalization(value)}
        rows={12}
        text={personalization}
        position="Right"
        className="rounded-md border shadow-sm"
      />
    </VStack>
  );
};

export default PersonalizationPrompt;
