import { Tabs, TabsProps } from "@chakra-ui/react";
import { createContext, useContext, ReactNode, useMemo } from "react";
import { createStore, StoreApi, useStore } from "zustand";

interface TabControllerState {
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}

const createTabContextState = () =>
  createStore<TabControllerState>((set) => ({
    activeTabIndex: 0,
    setActiveTabIndex: (index) => set({ activeTabIndex: index }),
  }));

const TabControllerContext = createContext<StoreApi<TabControllerState> | null>(
  null,
);

export const ControlledTabs = ({
  children,
  ...props
}: { children: ReactNode } & TabsProps) => {
  const store = useMemo(() => createTabContextState(), []);

  const activeTabIndex = useStore(store, (state) => state.activeTabIndex);
  return (
    <TabControllerContext.Provider value={store}>
      <Tabs
        onChange={(index) => store.setState({ activeTabIndex: index })}
        index={activeTabIndex}
        {...props}
      >
        {children}
      </Tabs>
    </TabControllerContext.Provider>
  );
};

export const useTabController = () => {
  const store = useContext<StoreApi<TabControllerState> | null>(
    TabControllerContext,
  );
  if (!store) {
    throw new Error(
      "useTabController must be used within a TabControllerProvider",
    );
  }
  return useStore(store);
};
