import { TProviderOption } from "@/components/Common/ProviderWaterfall/types";
import leadMagicImg from "@/assets/svgs/leadmagic.svg";
import foraragerImg from "@/assets/svgs/forager.svg";
import nimblerImg from "@/assets/svgs/nimbler.svg";
import datagmaImg from "@/assets/svgs/datagma.svg";
import rocketReachImg from "@/assets/svgs/rocketreach.png";
import peopleDataLabs from "@/assets/svgs/people_data_labs.png";
const providerOptionsList: TProviderOption[] = [
  {
    value: "leadmagic",
    label: "Leadmagic",
    icon: leadMagicImg,
    credits: 3,
    apikeyParams: {
      keyName: "leadMagicKey",
      enableUserApiKey: true,
      enablePersanaApiKey: true,
    },
  },
  {
    value: "Datagma",
    label: "Datagma",
    icon: datagmaImg,
    credits: 15,
    apikeyParams: {
      keyName: "Datagma",
      enableUserApiKey: true,
      enablePersanaApiKey: true,
    },
  },
  {
    value: "nimblerApiKey",
    label: "Nimbler",
    icon: nimblerImg,
    apikeyParams: {
      keyName: "nimblerApiKey",
      enableUserApiKey: true,
      enablePersanaApiKey: true,
    },
  },
  {
    value: "foragerApiKey",
    label: "Forager",
    icon: foraragerImg,
    apikeyParams: {
      keyName: "foragerApiKey",
      enableUserApiKey: true,
      enablePersanaApiKey: true,
    },
  },
  {
    value: "rocketReachApiKey",
    label: "Rocket Reach",
    icon: rocketReachImg,
    credits: 1,
    apikeyParams: {
      keyName: "rocketReachApiKey",
      enableUserApiKey: true,
      enablePersanaApiKey: false,
    },
  },
  {
    value: "peopleDataLabsApiKey",
    label: "PeopleDataLabs",
    icon: peopleDataLabs,
    credits: 1,
    apikeyParams: {
      keyName: "peopleDataLabsApiKey",
      enableUserApiKey: true,
      enablePersanaApiKey: false,
    },
  },
];
export const providerOptions = providerOptionsList;
export const defaultProviders = providerOptions.slice(0, 4); // Don't include providers -> enablePersanaApiKey: false
