import { useState } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  Avatar,
  Box,
  Button,
  Text,
  Stack,
  IconButton,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { ActiveJobsType } from "@/types/accountMonitoring.types";
import Icons from "@/components/Icons";
export const StatusRenderer = (
  props: CustomCellRendererProps<ActiveJobsType>,
) => {
  const isNewCompany = !!props; // dummy data
  return (
    <Stack w="full" h={"full"} placeContent={"center"} px={8}>
      <Button
        borderRadius={"20px"}
        w={"8em"}
        isDisabled={!isNewCompany}
        fontSize={"13px"}
        color={"#5024DC"}
        textTransform={"capitalize"}
      >
        {isNewCompany ? "New Job" : "No Change"}
      </Button>
    </Stack>
  );
};
export const CompanyNameRender = ({
  data,
}: CustomCellRendererProps<ActiveJobsType>) => {
  const companyName = data?.companyName;
  const companyLink = data?.companyWebsite;
  const logo = data?.thumbnail;
  if (!companyName) return null;
  return (
    <Stack
      className="flex gap-4 truncate"
      direction={"row"}
      placeItems={"center"}
      h={"full"}
      w={"full"}
    >
      <Avatar
        color={"white"}
        bg={"#5024DC"}
        border={"solid"}
        borderWidth={"2px"}
        borderColor={"black"}
        size={"sm"}
        name={companyName}
        src={logo}
      ></Avatar>

      <Stack gap={1} lineHeight={"1em"} className="truncate">
        <Box className="truncate" fontSize={"14px"} fontWeight={400}>
          {companyName}
        </Box>
        {companyLink && (
          <Box fontSize={"13px"} fontWeight={"400"} color={"#5024DC"}>
            <LinkCopyOpenRenderer link={companyLink} />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export function LinkCopyOpenRenderer({ link }: { link?: string }) {
  const [hover, setHover] = useState(false);
  if (!link) return null;
  return (
    <InputGroup
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      maxWidth={"full"}
      alignSelf={"center"}
      h={"1em"}
      onClick={() => window.open(link, "_blank")}
      cursor="pointer"
    >
      <InputLeftElement alignSelf={"center"} h={"1em"} opacity={hover ? 1 : 0}>
        <IconButton
          size="xs"
          ml={"-2em"}
          // bg="#F5F3FF"
          className="rounded-none bg-[#F5F3FF]"
          aria-label="Copy Link"
          icon={<Icons.CopyIcon />}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigator.clipboard.writeText(link);
          }}
        />
      </InputLeftElement>
      <Text
        p={0}
        className="pointer-events-none w-full bg-transparent p-0 !text-sm/[1em] !font-normal text-[#5024DC] outline-none"
      >
        {link}
      </Text>
    </InputGroup>
  );
}

export const JobTitleRenderer = ({
  data,
}: CustomCellRendererProps<ActiveJobsType>) => {
  const title = data?.title;
  const experience = data?.jobPosted;
  return (
    <Stack
      gap={1}
      lineHeight={"1em"}
      h={"full"}
      w={"full"}
      p={2}
      justifyContent={"center"}
      className="truncate"
    >
      <Box className="truncate !text-sm !font-normal text-[#5024DC]">
        {title}
      </Box>
      {experience && (
        <Text className="truncate !text-[13px] !font-normal text-[##7E889F]">
          {experience}
        </Text>
      )}
    </Stack>
  );
};

export const JobBoardsRenderer = ({
  data,
}: CustomCellRendererProps<ActiveJobsType>) => {
  const jobBoard = data?.jobBoard?.slice(4);
  return (
    <Stack className="flex size-full justify-center">
      <Text className="rounded bg-[#F5F3FF] px-2 !text-sm !font-normal text-[#5024DC]">
        {jobBoard}
      </Text>
    </Stack>
  );
};
