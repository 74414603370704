import Soc2Image from "@/assets/images/soc2-compliant.png";
import ProfileByEmailImage from "@/assets/images/find-profile-by-email.png";
import CompanyNewsImage from "@/assets/images/company-news.png";
import WorkEmailImage from "@/assets/images/work-email.png";
import CompanyHiringImage from "@/assets/images/company-hiring.png";
import PricingModelImage from "@/assets/images/pricing-model.png";
import CompanyCompetitorsImage from "@/assets/images/company-comp.png";

import Icons from "@/components/Icons";

export type TemplateGroup =
  (typeof TemplateGroups)[keyof typeof TemplateGroups];
export const TemplateGroups = {
  COMPANY_SEARCH: {
    name: "Company Search & Tracking",
    icon: Icons.FaBuildingCircleCheck,
  },
  // PROFILE_ENRICHMENT: "Profile Enrichment & People Search",
  // SAAS_MARKET_INSIGHTS: "SaaS & Market Insights",
  PROFILE_ENRICHMENT: {
    name: "Profile Enrichment & People Search",
    icon: Icons.PeopleIcon,
  },
  SAAS_MARKET_INSIGHTS: {
    name: "SaaS & Market Insights",
    icon: Icons.CompanyDetailsIcon,
  },
} as const;

export type TemplateTypes = {
  templateId: string;
  heading: string;
  title: string;
  url: string;
  image: string;
  group: TemplateGroup;
};

export const allTemplates: TemplateTypes[] = [
  {
    templateId: "1",
    heading: "Find Companies",
    title: "Find whether companies are SOC 2 compliant",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZlYjQyY2NiZTczNTczM2I1NThmNDJiIiwiaWF0IjoxNzI2Njk3NzE3LCJleHAiOjE3NTgyNTUzMTd9.aI_LpLdebpg3xh1gHqFxlpNnTewxnd0pF1V5A8kinf0",
    image: Soc2Image,
    group: TemplateGroups.COMPANY_SEARCH,
  },
  {
    templateId: "2",
    heading: "LinkedIn URL",
    title: "Find linkedIn URL from work email",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZlYzJmM2E4ZWU1M2RlMjIyZTM5MTc1IiwiaWF0IjoxNzI2NzU0OTA1LCJleHAiOjE3NTgzMTI1MDV9.oTqrg-cWW_hvdmsoCKlta_hMzhQqoYsAPLElZwGgPDE",
    image: ProfileByEmailImage,
    group: TemplateGroups.PROFILE_ENRICHMENT,
  },
  {
    templateId: "3",
    heading: "Work Email Waterfall",
    title: "Find email with name and company using waterfall model",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzFiMzQyMzJkOGUyY2ViZGZkZmJkIiwiaWF0IjoxNzI3MjA4NDMwLCJleHAiOjE3NTg3NjYwMzB9.lDkbyzECZqFIRnlwevQjdhOtKtDYVr9mlKAsCHieqBk",
    image: WorkEmailImage,
    group: TemplateGroups.PROFILE_ENRICHMENT,
  },
  {
    templateId: "4",
    heading: "Track Companies Hiring",
    title: "Track a list of companies for hiring",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzBiYjRmZWUxNjU1MGViYjFmMGY1IiwiaWF0IjoxNzI3MjA3NTkxLCJleHAiOjE3NTg3NjUxOTF9.ORYbfJDuAsazI29uxArSRSj-g3_ep201oIhRp27UWh4",
    image: CompanyHiringImage,
    group: TemplateGroups.COMPANY_SEARCH,
  },
  {
    templateId: "5",
    heading: "Find Company News",
    title: "Find company news from company URL",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzE4Yzk0ZjQ5ZTUyN2Q4Y2U0OTRlIiwiaWF0IjoxNzI3MjA4MTk1LCJleHAiOjE3NTg3NjU3OTV9.vFjp5l9SrS7fxrhFNglW1o8LjH8u8VG57VmRJfnvQro",
    image: CompanyNewsImage,
    group: TemplateGroups.COMPANY_SEARCH,
  },
  {
    templateId: "6",
    heading: "Find Company Pricing",
    title: "Find company pricing using Persana's Quantum Agent.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzNhMDU3N2VkMzU0ZmVhYTUyYmI5IiwiaWF0IjoxNzI3MjE2NTMwLCJleHAiOjE3NTg3NzQxMzB9.wMdc_YlpLjdlQgaYqxPTVYRWF5p_CXBHHwABIfaSdG0",
    image: PricingModelImage,
    group: TemplateGroups.COMPANY_SEARCH,
  },
  {
    templateId: "7",
    heading: "Find Company Revenue",
    title: "Find company revenue using Persana's Quantum Agent.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzNjMTAxOWQ5OWRlMWNiZDYyMGUxIiwiaWF0IjoxNzI3MjE2OTA1LCJleHAiOjE3NTg3NzQ1MDV9.o5IQjIc6ZII28pqguPSYpCBAaPj9jJapffWwEz6y9cs",
    image: CompanyCompetitorsImage,
    group: TemplateGroups.COMPANY_SEARCH,
  },
  {
    templateId: "8",
    heading: "Find Company Competitors",
    title:
      "Survey competitors and pinpoint differences using Persana's Quantum Agent.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzNkMjUyMzg3NzI2NjdmNzdiYzQ5IiwiaWF0IjoxNzI3MjE3MDY1LCJleHAiOjE3NTg3NzQ2NjV9.L3OHuPwGnd8Jf64Dvr0T4rnqDY0E3p_-ZwAES7U-4us",
    image: CompanyCompetitorsImage,
    group: TemplateGroups.COMPANY_SEARCH,
  },
  {
    templateId: "9",
    heading: "Website Insights",
    title: "Find website visitor traffic and other insights of a website",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmM2M5YTBiNjFiM2U5NzIzZjMyMTEyIiwiaWF0IjoxNzI3MjUzMDIzLCJleHAiOjE3NTg4MTA2MjN9.r6dY8Aj9eHXwH-K9zBH4IqUPWpaXX18Eu1aTb_MItgQ",
    image: CompanyCompetitorsImage,
    group: TemplateGroups.SAAS_MARKET_INSIGHTS,
  },
  {
    templateId: "10",
    heading: "Podcast Appearance",
    title: "Find podcast appearance + write personalized first line:",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmNmViNmU5ZmZjYTNiMjRjZTk4NTI3IiwiaWF0IjoxNzI3NDU4OTE1LCJleHAiOjE3NTkwMTY1MTV9.stPttvRoZVIJOkKMVWT_FTYCqiiPO_tnByu7JnEoPPA",
    image: CompanyCompetitorsImage,
    group: TemplateGroups.SAAS_MARKET_INSIGHTS,
  },
  {
    templateId: "11",
    heading: "Company Personalization",
    title: "Personalize company using company enrichment and AI Agent.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMjQwNzdmZWUxNjU1MGViYThmMWY5IiwiaWF0IjoxNzI3MTUyNTc2LCJleHAiOjE3NTg3MTAxNzZ9.kMXr45ZvpoqmD7SFdtWPOESj_eTD4hOhEyLJB5r0j2Y",
    image: CompanyCompetitorsImage,
    group: TemplateGroups.COMPANY_SEARCH,
  },
];
