import AutoPilotLeads from "@/assets/svgs/auto-pilot-leads.svg";
import AutoPilotSave from "@/assets/svgs/auto-pilot-save.svg";
import AutoPilotCampaign from "@/assets/svgs/auto-pilot-campaign.svg";

const Stats = () => {
  const stats = [
    {
      icon: AutoPilotLeads,
      title: "Leads Captured",
      count: "32,456",
    },
    {
      icon: AutoPilotSave,
      title: "Saved Pipelines",
      count: "56",
    },
    {
      icon: AutoPilotCampaign,
      title: "Ongoing Campaigns",
      count: "24",
    },
  ];
  return (
    <div className="flex items-center justify-center gap-[48px] px-[32px] py-[24px] border-y-2 border-[#F0EFF1] w-full mb-[32px]">
      {stats.map((item) => (
        <div className="flex items-center gap-3 bg-[#F5F3FF] border border-[#DDD8FF] rounded-[8px] px-[16px] py-[8px] min-w-[350px]">
          <img src={item.icon} />
          <p className="text-[16px] font-medium text-[#5024DC]">
            {item.title}:
          </p>
          <p className="text-[24px] font-medium text-[#5024DC]">{item.count}</p>
        </div>
      ))}
    </div>
  );
};

export default Stats;
