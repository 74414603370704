import GithubIntegration from "./GithubIntegration";
import HubspotIntegration from "./HubspotIntegration";
import OpenAIIntegration from "./OpenAIIntegration";
import OutreachIntegration from "./OutreachIntegration";
import SalesforceIntegration from "./SalesforceIntegration";
import SalesoftIntegration from "./SalesoftIntegration";
import SmartleadIntegration from "./SmartleadIntegration";
import GmailIntegration from "./GmailIntegration";
import HeyReachIntegration from "./HeyReachIntegration";
import InstantlyIntegration from "./InstantlyIntegration";
import LeadMagicIntegration from "./LeadMagicIntegration";
import LemListIntegration from "./LemListIntegration";
import SlackIntegration from "./SlackIntegration";
import ZenrowsIntegration from "./ZenrowsIntegration";
import LGMIntegration from "./LGMIntegration";
import ApifyIntegration from "./ApifyIntegration";
import ClaudeIntegration from "./ClaudeIntegration";
import DatagmaIntegration from "./DatagmaIntegration";
import IcypeasIntegration from "./IcypeasIntegration";
import ApolloIoIntegration from "./ApolloIoIntegration";
import PerplexityIntegration from "./PerplexityIntegration";
import ForagerIntegration from "./ForgerIntegration";
import NimblerIntegration from "./NimblerIntegration";
import RocketReachIntegration from "./RocketReachIntegration";
import PeopleDataLabsIntegration from "./PeopleDataLabsIntegration";
import ProspeoIntegration from "./ProspeoIntegration";
import DropContactIntegration from "./DropContactIntegration";
import FindyMailIntegration from "./FindyMailIntegration";
import HunterIoIntegration from "./HunterIoIntegration";

const Integrations = () => {
  const isNotProd = import.meta.env.VITE_MODE !== "production";

  return (
    <section className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
      <ApifyIntegration />
      <ApolloIoIntegration />
      <ClaudeIntegration />
      <DatagmaIntegration />
      <DropContactIntegration />
      <FindyMailIntegration />
      <ForagerIntegration />
      <IcypeasIntegration />
      <HeyReachIntegration />
      <HubspotIntegration />
      <HunterIoIntegration />
      <InstantlyIntegration />
      <LeadMagicIntegration />
      <LemListIntegration />
      <LGMIntegration />
      <NimblerIntegration />
      <PeopleDataLabsIntegration />
      <PerplexityIntegration />
      <ProspeoIntegration />
      <RocketReachIntegration />
      <OpenAIIntegration />
      <SmartleadIntegration />
      <ZenrowsIntegration />

      <GithubIntegration />
      <GmailIntegration />
      <OutreachIntegration />
      <SalesforceIntegration />
      <SalesoftIntegration />
      {isNotProd && <SlackIntegration />}
    </section>
  );
};

export default Integrations;
