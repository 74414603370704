import { useEffect, useMemo, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";

import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import ConditionalFormula from "../../Common/ConditionalFormula";
import SelectTemplate from "./SelectTemplate";
import { Divider, Flex } from "@chakra-ui/react";
import { SchemaCreator } from "@/components/Common/SchemaCreator/SchemaCreator";
import CustomAccordion from "@/components/Common/CustomAccordion";
import {
  summaryField,
  useColumnStore,
} from "@/components/Common/SchemaCreator/schema.store";
import CustomTextEditor from "../../Common/CustomTextEditor";
import { convertSlateToText, convertTextToSlate } from "@/utils";
import { AllowEmptyVar } from "./Utils/allowEmptyVar";

export const PersanaQuantum = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = usePresetsStore((state) => state.updateState);
  const [prompt, setPrompt] = useState<ANY>([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  const { selectedPresetValues, condition } = usePresetsStore((state) => ({
    selectedPresetValues: state.selectedPresetValues,
    condition: state.condition,
    body: state.body,
  }));
  const [allowEmptyVars, setAllowEmptyVars] = useState(false);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);
  const schema = useColumnStore((state) => state.columns);
  const extraColumns = useColumnStore((state) => state.addToTable);
  const mainColumn = useColumnStore((state) => state.mainColumn);

  useEffect(() => {
    if (selectedPresetValues) {
      setPrompt(convertTextToSlate(selectedPresetValues));
    }
  }, [selectedPresetValues]);
  useEffect(() => {
    if (condition) {
      updateEnrichmentState({
        runConditionFormula: condition,
        isInvalidConditionFormula: false,
      });
    }
  }, [condition]);
  useEffect(() => {
    useColumnStore.getState().reset();
  }, []);
  const payload = useMemo(() => {
    const isSummaryEnabled = !!schema.find(
      (col) => col.name === summaryField.name,
    );
    const effectiveMainColumn = isSummaryEnabled
      ? summaryField.name
      : mainColumn;
    return {
      allowEmptyVars: allowEmptyVars,
      mainColumn: effectiveMainColumn,
      schema: schema,
      slate: prompt,
      apiKeyName: "openAiApiKey",
      extraColumns: extraColumns
        .map((col) => schema?.[col]?.name)
        .filter(Boolean)
        .filter((col) => effectiveMainColumn !== col),
    };
  }, [extraColumns, mainColumn, prompt, schema, allowEmptyVars]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      console.log("enrichmentData", enrichmentData);

      if (enrichmentData) {
        setAllowEmptyVars(enrichmentData.allowEmptyVars ?? false);
        setPrompt(
          enrichmentData.slate ?? [
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ],
        );
        updateState({
          selectedPresetValues: enrichmentData.slate
            ? convertSlateToText(enrichmentData.slate).text
            : "",
          body: enrichmentData.slate ?? [
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ],
        });
        if (enrichmentData.schema) {
          useColumnStore.setState({
            columns: enrichmentData.schema,
            mainColumn: enrichmentData.mainColumn,
            editingIndex: null,
            addToTable:
              [
                ...(enrichmentData.extraColumns ?? []),
                ...enrichmentData.mainColumn,
              ]
                ?.map(
                  (col) =>
                    enrichmentData.schema?.findIndex((c) => c.name === col) ??
                    -1,
                )
                .filter((index) => index !== -1) ?? [],
          });
        }
        updateEnrichmentState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
          keySelect: "persanaApiKey",
        });
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={
            "Our most advanced agent capable of strong reasoning, browsing the internet and searching the web."
          }
          link=""
        />
        <Divider mb={4} />
        <SelectTemplate payload={{ prompt: convertSlateToText(prompt).text }} />
        <CustomAccordion
          isOpen={true}
          title="Prompt"
          description={
            <Flex mt={1} float={"right"} ml="auto" gap={2}>
              <AllowEmptyVar
                allowEmptyVars={allowEmptyVars}
                setAllowEmptyVars={setAllowEmptyVars}
              />
            </Flex>
          }
        >
          <CustomTextEditor
            slateValue={prompt}
            setSlateValue={(newInputText) => {
              setPrompt(newInputText);
            }}
            placeholder="Visit the website of the company at /Company Website, then explore their features section. Identify the most advanced feature they offer and how it differs from the basic features available."
          />
        </CustomAccordion>

        <CustomAccordion isDefaultOpen={true} title="Define Columns">
          <SchemaCreator readonly={viewMode === "edit"} prompt={prompt} />
        </CustomAccordion>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        onClear={() => {
          useColumnStore.getState().reset();
          updateState({
            body: null,
            selectedPresetValues: "",
          });
        }}
        isDisabled={!convertSlateToText(prompt)?.text}
        apiPayload={payload}
      />
    </>
  );
};
