import { Button } from "@chakra-ui/react";
import { useState } from "react";
import StarterScreenImport from "@/assets/svgs/starter-screen-import.svg";
import StarterScreenSales from "@/assets/svgs/starter-screen-sales.svg";
import StarterScreenLogo from "@/assets/svgs/starter-screen-home.svg";
import StarterScreenSelect from "@/assets/svgs/starter-screen-select.svg";
import StarterScreenDeselect from "@/assets/svgs/starter-screen-deselect.svg";
import { useModalStore } from "@/stores/modal.store";

const OptionCard = ({
  action,
  selectedAction,
  onClick,
  icon,
  title,
  description,
}: {
  action: "import" | "sales-gpt";
  selectedAction: "import" | "sales-gpt";
  onClick: () => void;
  icon: string;
  title: string;
  description: string;
}) => {
  const isSelected = selectedAction === action;
  return (
    <div
      className={`p-[24px] flex items-start justify-between border rounded-[12px] min-h-[120px] min-w-[400px] cursor-pointer hover:shadow-md ${
        isSelected ? "border-[#C03EDC]" : "border-[#D0D4DC]"
      }`}
      onClick={onClick}
    >
      <div className="flex items-start justify-center gap-6">
        <img src={icon} alt={`${action} icon`} />
        <div>
          <p className="text-[1.2rem] text-[#0F0D1C] font-[600] mb-1">
            {title}
          </p>
          <p
            className="text-[0.8rem] text-[#7E889F]"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
      <img
        src={isSelected ? StarterScreenSelect : StarterScreenDeselect}
        alt="selection indicator"
      />
    </div>
  );
};

const StarterScreen = ({
  setActiveStep,
}: {
  setActiveStep: (val: number) => void;
}) => {
  const { updateState } = useModalStore((state) => state);
  const [selectedAction, setSelectedAction] = useState<"import" | "sales-gpt">(
    "import",
  );

  const handleClick = (action: "import" | "sales-gpt") => {
    if (action === "import") {
      updateState({
        isOpenModal: true,
        modalData: {
          modalType: "import-list-modal",
          metaData: { highlightCsv: true },
        },
      });
    } else {
      setActiveStep(1);
    }
  };

  return (
    <div className="flex items-center gap-[10px] justify-center flex-col">
      <img
        src={StarterScreenLogo}
        alt="Starter Screen Logo"
        width={90}
        height={80}
      />
      <div className="flex flex-col items-center justify-center gap-1">
        <p className="text-[32px] font-[500] text-[#0F0D1C]">
          Now, let’s build your list.
        </p>
        <p className="text-[14px] text-[#373F51]">
          Choose an option to start with.
        </p>
      </div>

      <div className="flex items-center justify-center gap-2 py-[16px]">
        <OptionCard
          action="import"
          selectedAction={selectedAction}
          onClick={() => setSelectedAction("import")}
          icon={StarterScreenImport}
          title="Import a list"
          description="Import your existing contact list <br /> via CSV or CRM."
        />
        <OptionCard
          action="sales-gpt"
          selectedAction={selectedAction}
          onClick={() => setSelectedAction("sales-gpt")}
          icon={StarterScreenSales}
          title="Try our new SalesGPT"
          description="Find the right people in seconds <br /> with our AI-powered search."
        />
      </div>
      <Button
        className="w-full hover:!bg-[#C03EDC] hover:!shadow-lg"
        bg={"#C03EDC"}
        textColor={"#fff"}
        onClick={() => handleClick(selectedAction)}
      >
        Let's go!
      </Button>
    </div>
  );
};

export default StarterScreen;
