import { Badge, Button, ButtonGroup, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import React, { useState } from "react";

import BlueStar from "@/assets/svgs/blue-star.svg";
import CompaniesIcon from "@/assets/svgs/companies.svg";
import PeopleIcon from "@/assets/svgs/people.svg";

import leadService from "@/services/leads.service";

import { useModalStore } from "@/stores/modal.store";
import { useTabStore } from "../PeopleSearch/store";
import useTypedText from "./useTypedText";

import Star from "@/assets/svgs/star.svg";
const AIAssistant = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [inputValue, setInputValue] = useState("");
  const getFirstStaleTab = useTabStore(
    (state) => state.getOrCreateFirstStateTab,
  );
  const placeholders = [
    "Ask me anything...",
    "Does Salesforce have an API?",
    "Where is the headquarters of Meta?",
    "Find healthcare companies in United States",
    "Find people who work at Google as a Software Engineer and lives in San Francisco",
  ];

  const dynamicPlaceholder = useTypedText(placeholders);
  const updateState = useModalStore((state) => state.updateState);

  const { mutateAsync: searchPeopleUsingGPTMutateAsync, isPending } =
    useMutation({
      mutationFn: (input: string) =>
        leadService.searchPeopleUsingGPT({ prompt: input }),
      onSuccess: (response) => {
        // setInputValue("");
        if (response.type === "AGENT") {
          // onOpen();
          setInputValue("");
          updateState({
            isOpenModal: true,
            modalData: { modalType: "get-started" },
          });
          return;
        }

        if (response.type === "PEOPLE_SEARCH") {
          if (response.data?.peopleSearchPayload) {
            const tab = getFirstStaleTab();
            tab.store.setState({
              peopleSearchPayload: response.data?.peopleSearchPayload,
            });
            useTabStore.getState().activateTab(tab.id);
            navigate({
              to: `/search`,
            });
          }
          return;
        }

        if (!response?.data?.tableId) return;
        navigate({
          to: `/table/$tableId`,
          params: {
            tableId: response?.data?.tableId,
          },
        });
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      inputValue && searchPeopleUsingGPTMutateAsync(inputValue);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };

  return (
    <>
      <div className="relative mx-[24px] mb-[12px] overflow-hidden rounded-[8px] border border-[#7846cc] bg-white">
        <div className="px-[16px] py-[12px]">
          <p className="text-[20px] font-[600] text-[#0F0D1C]">
            Build your lead list with AI
          </p>
          <Badge
            size="sm"
            variant="solid"
            className="shine-effect absolute right-[0.5em] top-[0.5em] !flex items-center !rounded-[3em] !bg-[#b86ddf] !px-3 !py-1 capitalize !text-white opacity-70"
          >
            <img
              className="mr-2 "
              src={Star}
              alt="star"
              width={20}
              height={20}
            />
            New
          </Badge>
        </div>
        <div className="flex flex-col gap-[16px] px-[16px] pb-[12px]">
          <textarea
            onKeyDown={handleKeyDown}
            value={inputValue}
            onChange={handleChange}
            placeholder={dynamicPlaceholder}
            rows={2}
            className="!h-[60px] !w-full !resize-none !rounded-md !p-2 shadow-none"
            style={{
              border: "1px solid #d8d2ec",
            }}
          />

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-[12px]">
              <ButtonGroup>
                <Button
                  leftIcon={
                    <img src={PeopleIcon} alt="people" width={20} height={20} />
                  }
                  className="rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#5024DC]"
                  onClick={() => {
                    navigate({ to: "/search" });
                  }}
                  variant="secondary"
                  size="sm"
                >
                  People
                </Button>
                <Button
                  leftIcon={
                    <img
                      src={CompaniesIcon}
                      alt="companies"
                      width={20}
                      height={20}
                    />
                  }
                  className="rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#5024DC]"
                  onClick={() => {
                    updateState({
                      isOpenModal: true,
                      modalData: {
                        modalType: "linkedin-company-search",
                        metaData: { openPreviousModal: false },
                      },
                    });
                  }}
                  variant="secondary"
                  size="sm"
                >
                  Companies
                </Button>
              </ButtonGroup>
            </div>
            <Button
              isLoading={isPending}
              onClick={() => searchPeopleUsingGPTMutateAsync(inputValue)}
              variant="outline"
              leftIcon={
                <img src={BlueStar} alt="star" width={20} height={20} />
              }
              className="flex w-fit cursor-pointer rounded-[6px] !border !border-[#693DC7] !bg-white !text-[#693dc7]"
              isDisabled={!inputValue}
            >
              Enrich Data
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIAssistant;
