import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { MultiValue } from "react-select";
import AsyncSelect from "react-select/async";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import misServices from "@/services/misc.services";

interface Props {
  value?: Option[];
  onChange?: (selectedValues: Option[]) => void;
  label?: string;
}

interface CompanyNameResult {
  company_name: string;
  employee_count: number;
  follower_count: number;
  id: number;
  square_logo_url: string;
  website: string;
}

const CompanyNameSearches = ({
  label = "Company Name",
  value,
  onChange,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    () => value || [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState<{
    [key: string]: Option[];
  }>({});

  const fetchOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length <= 2) {
        return [];
      }

      if (fetchedOptions[inputValue]) {
        return fetchedOptions[inputValue];
      }

      try {
        setIsLoading(true);
        const response = await misServices.getMiscData<any>("company-names", {
          search: inputValue,
        });
        console.log("myResponse", response);

        const options = response?.data?.data?.results?.map(
          (result: CompanyNameResult) => ({
            label: result.company_name,
            value: result.id,
          }),
        );

        setFetchedOptions((prev) => ({ ...prev, [inputValue]: options }));
        return options;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [fetchedOptions],
  );

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: (options: any[]) => void) => {
      fetchOptions(inputValue)
        .then((options) => {
          console.log("myOptions", options);
          callback(options || []);
        })
        .catch((error) => {
          console.error("Error fetching options:", error);
          callback([]);
        });
    }, 500),
    [fetchOptions],
  );

  const handleChange = (newValue: MultiValue<Option>) => {
    setSelectedOptions([...newValue]);
    onChange && onChange(newValue as Option[]);
  };

  return (
    <>
      <p className="mb-1 text-[14px] font-[600] text-[#1a202c]">{label}</p>
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        onChange={handleChange}
        placeholder="Search for company names..."
        isClearable
        defaultOptions={[]}
        noOptionsMessage={() => "No results found"}
        isLoading={isLoading}
        value={selectedOptions}
        isMulti
        closeMenuOnSelect={false}
        closeMenuOnScroll={false}
      />
    </>
  );
};

export default CompanyNameSearches;
