import { Box } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { useGridOptions } from "./hooks/useGridOptions";
import { NoTablesView } from "./components/NoTablesView";
import { CreateNew } from "./components/CreateNew";
import StyleProvider from "@/providers/StyleProvider";
import "./index.css";
import { TRowId } from "./types";
import { userStore } from "@/stores/user.store";

const TableList = ({
  variant = "full",
}: {
  path?: TRowId[];
  variant?: "mini" | "full";
}) => {
  const gridOptions = useGridOptions();
  const isUpgradeBannerShown = userStore((state) =>
    state.isUpgradeBannerShown(),
  );
  const initOptions =
    variant === "mini"
      ? {
          editable: false,
          showUpdatedAt: false,
          showFolders: false,
        }
      : {
          editable: true,
          showUpdatedAt: true,
          showFolders: true,
        };
  return (
    <StyleProvider>
      <Box overflowX="auto" className="folder-table mt-4">
        <Box
          overflowY="hidden"
          maxHeight={`calc(100vh - 410px - ${isUpgradeBannerShown ? "45px" : "0px"})`}
        >
          {initOptions.editable && <CreateNew mr="2em" />}
          <AgGridReact
            context={{
              ...(gridOptions.context ?? null),
              initOptions,
              variant,
            }}
            className="custom-scrollbar ag-theme-quartz !h-[540px]"
            gridOptions={gridOptions}
            noRowsOverlayComponent={NoTablesView}
            loadingOverlayComponent={NoTablesView}
          />
        </Box>
      </Box>
    </StyleProvider>
  );
};
export default TableList;
