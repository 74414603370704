import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

export type Actions<TState = State> = {
  updateState: (payload: Partial<TState>) => void;
};

export type PaginationType = {
  total_entries: number;
  total_pages: number;
  page: number;
  per_page: number;
};

export type State = {
  instanceId?: string;
  naturalFilters?: {
    natural_query: string;
    query_title: string;
  };
  searchLeadsResult: {
    profiles: any[];
    apiPath?: string;
    pagination: PaginationType;
    cacheKey?: string;
    columsMapping?: any[];
  } | null;
  searchLeadsColumnNames: any[];
  searchLeadsResultRows: any[];
  selectedLeads: any[];
  applyFilterData?: boolean;
  selectionAction:
    | "select-all"
    | "clear-all"
    | "select-page"
    | "range-selection"
    | "";
  selectionCount: number;
  currentSearchPayload: any;
  isSearching?: boolean;
  isShowErrorModal?: boolean;
  storagePlatforms?: { label: string; value: string }[] | null;
  sendSlackNotifications?: boolean;
  selectedChannel?: { label: string; value: string } | null;
};

export const useSearchLeadsStore = create<State & Actions>((set) => ({
  instanceId: `people-search-${Math.random().toString(16).slice(2)}`,
  searchLeadsResult: null,
  searchLeadsColumnNames: [],
  searchLeadsResultRows: [],
  selectedLeads: [],
  selectionAction: "",
  selectionCount: 0,
  currentSearchPayload: {},
  isSearching: false,
  isShowErrorModal: false,
  storagePlatforms: [],
  sendSlackNotifications: false,
  selectedChannel: { label: "", value: "" },
  updateState: (payload) => set(() => payload),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("SearchLeads", useSearchLeadsStore);
}
