import { useEffect, useState } from "react";
import { Switch, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import SaveAndRunEnrichment from "../../../Common/SaveAndRun";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import Icons from "@/components/Icons";
import { useProviderList } from "@/hooks/useProviderData";
import providerService from "@/services/providers.service";
import ConditionalFormula from "@/components/Enrichment/Common/ConditionalFormula";
import CustomAccordion from "@/components/Common/CustomAccordion";
import CustomTextEditor from "@/components/Enrichment/Common/CustomTextEditor";
import { initialSlateValue } from "../../HttpApi/consts";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import { TSelectedColumnOption } from "@/types/common.types";

export interface ObjectFieldsType {
  displayName: string;
  name: string;
  optional: boolean;
  type: string;
}

interface Props {
  action?: "create" | "update";
}

function SalesForceCreateOrUpdateRecord({ action }: Props) {
  const [objects, setObjects] = useState<Option[]>([]);
  const [objectFields, setObjectFields] = useState<ObjectFieldsType[]>([]);
  const [selectedObject, setSelectedObject] = useState<Option | null>(null);
  const [isOverrideRules, setIsOverrideRules] = useState(false);
  const [fieldsValue, setFieldsValue] = useState<{ [key: string]: any }>({});
  const [editedFields, setEditedFields] = useState<{ [key: string]: any }>({});
  const [selectedRecordId, setSelectedRecordId] =
    useState<TSelectedColumnOption | null>(null);

  const toast = useToast();
  const { data: providerList, isLoading } = useProviderList();
  const salesforceProvider = providerList?.data?.find(
    (provider) => provider.name === "salesforce",
  );
  const isSalesforceConnected = salesforceProvider?.metaData;

  console.log("action", action);

  const handleConnect = async () => {
    providerService.openSalesforceOAuth();
  };

  const { mutateAsync: fetchObjects, isPending: isFetchObjectsLoading } =
    useMutation({
      mutationFn: (keyId: string) => {
        return providerService.getSalesForceObjects(keyId);
      },
      onSuccess: (response) => {
        if (response?.success) {
          setObjects(response?.data);
        } else {
          toast({
            // @ts-ignore
            title: response?.message || "Something went wrong.",
            status: "error",
            isClosable: true,
            position: "top-right",
          });
        }
      },
    });

  const { mutateAsync: fetchFields, isPending: isFetchingFields } = useMutation(
    {
      mutationFn: ({ keyId, objName }: { keyId: string; objName: string }) => {
        return providerService.getSalesForceFields({
          keyId,
          objectName: objName,
        });
      },
      onSuccess: (response) => {
        if (response?.success) {
          if (response?.data?.length) {
            const objectFields = response?.data?.reduce(
              (acc: any, item: ObjectFieldsType) => {
                if (item.name) {
                  acc[item.name] = initialSlateValue;
                }
                return acc;
              },
              {},
            );

            setFieldsValue(objectFields);
          }

          setObjectFields(response?.data);
        } else {
          toast({
            // @ts-ignore
            title: response?.message || "Something went wrong.",
            status: "error",
            isClosable: true,
            position: "top-right",
          });
        }
      },
    },
  );

  const handleObjectChange = async (value: Option) => {
    setSelectedObject(value);
    await fetchFields({
      keyId: salesforceProvider?._id || "",
      objName: value.value,
    });
  };

  const handleFieldValuesChange = (keyName: string, value: any) => {
    setFieldsValue({
      ...fieldsValue,
      [keyName]: value,
    });

    setEditedFields({
      ...editedFields,
      [keyName]: true,
    });
  };

  const getFilteredFields = () => {
    const editedKeys = Object.keys(editedFields || {});
    const filteredFields = editedKeys.reduce(
      (acc, key) => {
        if (fieldsValue.hasOwnProperty(key)) {
          acc[key] = fieldsValue[key];
        }
        return acc;
      },
      {} as { [key: string]: any },
    );

    return filteredFields;
  };

  useEffect(() => {
    if (isSalesforceConnected) {
      fetchObjects(salesforceProvider?._id || "");
    }
  }, [providerList]);

  return (
    <>
      <div className="grow overflow-y-auto p-4 font-title space-y-6">
        {!isSalesforceConnected && (
          <button
            className={`w-full py-1 text-center bg-primary text-white rounded-md font-semibold`}
            disabled={isLoading}
            onClick={() => {
              handleConnect();
            }}
          >
            Connect to Salesforce
          </button>
        )}

        {action === "update" && (
          <div className="space-y-1">
            <p className="font-semibold">Record ID</p>
            <SelectColumnDropdown
              selectedOption={selectedRecordId}
              setSelectedOption={setSelectedRecordId}
            />
            <p className="text-xs text-[#717989] font-medium">
              The unique identifier of the record you want to update.
            </p>
          </div>
        )}

        <div className="space-y-1">
          <div className="flex justify-between items-center">
            <p className="font-semibold">Salesforce Object</p>
            <button
              className="flex items-center space-x-1.5 text-sm"
              onClick={() => {
                if (isSalesforceConnected) {
                  fetchObjects(salesforceProvider?._id || "");
                }
              }}
            >
              <Icons.RefreshIconDoubleArr
                className={`${isFetchObjectsLoading ? "animate-spin" : ""}`}
              />
              <span className="font-medium">Refresh</span>
            </button>
          </div>
          <MultiSelectDropdown
            isMulti={false}
            placeholder="Select an item from the dropdown..."
            closeMenuOnSelect
            options={objects}
            value={selectedObject}
            onChange={(value) => {
              if (value === null) {
                setSelectedObject(null);
                setFieldsValue({});
                setObjectFields([]);
                return;
              }

              handleObjectChange(value as Option);
            }}
            isDisabled={!isSalesforceConnected}
            isLoading={isFetchObjectsLoading || isFetchingFields}
            isCreateAble={false}
          />
          <p className="text-xs text-[#717989] font-medium">
            The object type to look for in your Salesforce instance.
          </p>
        </div>
        <div>
          <div className="w-full flex justify-between items-center pb-0.5">
            <p className="font-semibold">
              Duplicate Rules Override{" "}
              <span className="text-[13px] text-[#525a69]">( Optional )</span>
            </p>
            <Switch
              isChecked={isOverrideRules}
              onChange={(e) => setIsOverrideRules(e.target.checked)}
            />
          </div>
          <p className="text-xs font-medium text-[#717989]">
            If switched on and you have a{" "}
            <a
              className="text-primary"
              target="_blank"
              href="https://help.salesforce.com/s/articleView?id=sf.duplicate_rules_map_of_reference.htm&type=5"
            >
              duplicate rule
            </a>
            , Persana will override the duplicate rule and create a new record
            even if it's a duplicate.
          </p>
        </div>
        {objectFields.length > 0 && (
          <div className="space-y-6">
            {objectFields.map((field) => {
              return (
                <CustomAccordion
                  title={
                    <>
                      <span>{field.displayName}</span>{" "}
                      <span className="font-medium text-[#525a69] text-[13px]">
                        {field?.optional ? "(Optional)" : ""}
                      </span>
                    </>
                  }
                  titleClassName="font-title text-sm"
                >
                  <CustomTextEditor
                    editorHeight={"3rem"}
                    slateValue={fieldsValue[field.name]}
                    setSlateValue={(value) => {
                      handleFieldValuesChange(field.name, value);
                    }}
                    placeholder={`Start typing or use the dropdown to select a column.`}
                  />
                </CustomAccordion>
              );
            })}
          </div>
        )}
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={
          action === "update"
            ? !selectedRecordId?.keyId || !selectedObject?.value
            : !selectedObject?.value
        }
        apiPayload={{
          sObjectName: selectedObject?.value,
          fieldsValue: getFilteredFields(),
          runActionType: action,
          recordId: selectedRecordId?.keyId,
        }}
      />
    </>
  );
}

export default SalesForceCreateOrUpdateRecord;
