import TableViews from "../TableViews";
import { ButtonGroup } from "@chakra-ui/react";
import { useProcessingStore } from "@/stores/processing.store";
import ExportData from "./ExportData";
import ImportData from "./ImportData";

const BottomPageActions = () => {
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const processingData = useProcessingStore((state) => state.processingData);
  const isAnyProcessRunning = !!processingData?.length;

  return (
    <>
      {!isTablePreviewPage && (
        <div className="flex items-center gap-2">
          <ImportData isAnyProcessRunning={isAnyProcessRunning} />
          <ExportData isAnyProcessRunning={isAnyProcessRunning} />
          <ButtonGroup size={"xs"} isAttached className="flex items-center">
            <TableViews />
          </ButtonGroup>
        </div>
      )}
    </>
  );
};

export default BottomPageActions;
