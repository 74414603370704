import MakeAPICall from "@/lib/apiCalls";

import type {
  HttpPresetsResponseData,
  PresetDataResponse,
  PresetEnrichmentType,
} from "@/types/presets.types";

class PresetsService {
  constructor(private apiCaller = MakeAPICall) {}

  async fetchHttpPresets(enrichmentType: PresetEnrichmentType) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/httpApiPresets/all`,
    });
    try {
      const response = await apiCall.get<HttpPresetsResponseData[]>({
        params: {
          enrichmentType: enrichmentType,
        },
      });
      if (response.success) {
        return {
          success: true,
          data: response.data,
        };
      } else {
        return {
          success: false,
          error: response.error,
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error: error.message,
      };
    }
  }

  async saveHttpPreset(payload: any) {
    const apiCall = new this.apiCaller({
      url: `${
        import.meta.env.VITE_OLD_BE_BASE_URL
      }/api/httpApiPresets/saveCustomHttpPresets`,
      payload: payload,
    });
    try {
      await apiCall.post({});

      return {
        success: true,
      };
    } catch (error: any) {
      return {
        success: false,
        error: error.message,
      };
    }
  }

  async getPresets(
    enrichmentType: PresetEnrichmentType,
  ): Promise<PresetDataResponse[]> {
    const apiCall = new MakeAPICall({
      apiPath: `presets/${enrichmentType}`,
    });
    const response = await apiCall.get<{
      success: boolean;
      data?: PresetDataResponse[];
      error?: string;
    }>();
    return (response.data || []) as PresetDataResponse[];
  }

  async createNewPreset(preset: Partial<PresetDataResponse>) {
    const apiCall = new MakeAPICall({
      apiPath: "presets",
      payload: preset,
    });
    const response = await apiCall.post<{
      success: boolean;
      message?: string;
      error?: string;
    }>();
    if (!response.success) {
      throw response.error;
    }
    return response.data;
  }
}

const presetsService = new PresetsService();

export default presetsService;
