import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

import StarterList from "@/components/Home/StarterList";
import Icons from "@/components/Icons";
import tableService from "@/services/table.service";
import { useModalStore } from "@/stores/modal.store";
import { useSearchLeadsStore } from "@/stores/searchleads.store";

import { TableOptions, allActions } from "./constants";
import { ActionType } from "./types";
import { userStore } from "@/stores/user.store";

interface ColumnPayload {
  columns: { name: string }[];
}

const GetStartedModal = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync: createBlankTable, isPending } = useMutation({
    mutationFn: (payload: ColumnPayload | undefined) =>
      tableService.createBlankTable(payload),
  });
  const currentUser = userStore((state) => state.currentUser);
  const isPro = ["pro", "persana_pro"].includes(currentUser?.planType ?? "");

  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  const handleCardClick = (actionType?: ActionType) => {
    switch (actionType) {
      case "people": {
        updateState({
          isOpenModal: false,
        });

        updateLeadState({
          searchLeadsResult: null,
        });
        navigate({ to: "/search" });
        break;
      }
      case "companies": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "linkedin-company-search",
            metaData: { openPreviousModal: true },
          },
        });

        updateLeadState({
          searchLeadsResult: null,
        });
        break;
      }
      case "google-srp": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "google-srp",
            metaData: { openPreviousModal: true },
          },
        });
        break;
      }
      case "csv": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "import-csv",
            metaData: { openPreviousModal: true },
          },
        });
        break;
      }
      case "github": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "github-stargazers",
            metaData: { openPreviousModal: false },
          },
        });
        break;
      }
      case "localBusinesses": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "local-businesses",
            metaData: { openPreviousModal: false },
          },
        });
        break;
      }
      case "webhook": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "webhook",
            metaData: {
              openPreviousModal: true,
              modalTitle: "Creating Webhook Table",
              isLeads: true,
            },
          },
        });
        break;
      }

      case "salesforce": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "salesforce",
            metaData: {
              openPreviousModal: true,
              previousModalType: "get-started",
              modalTitle: "Import Records from Salesforce",
              isLeads: true,
            },
          },
        });
        break;
      }
      case "hubspot": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "hubspot-contact-import",
            metaData: { openPreviousModal: false },
          },
        });
        break;
      }
      case "salesNavImport": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "sales-nav-import",
            metaData: { openPreviousModal: false },
          },
        });
        break;
      }
    }
  };

  const handleCreateBlankTable = async (payload: ColumnPayload | undefined) => {
    await createBlankTable(payload, {
      onSuccess: (response) => {
        if (!response.success || !response.data?.success) {
          toast({
            title: "Error",
            description: response.data?.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        handleModalClose();
        const tableId = response.data?.data?.tableId;
        if (tableId) {
          navigate({
            to: "/table/$tableId",
            params: {
              tableId,
            },
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (isOpenModal && modalData?.metaData?.scrollToBottom) {
        const listDiv = document.getElementById("starterTables");
        listDiv?.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }, 400);
  }, [isOpenModal, modalData]);

  const scrollToElement = (elId: string) => {
    const el = document.getElementById(elId);
    console.log(el);
    el?.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "center",
    });
    el!.classList.add("ping-border");
    (el?.querySelector("input, select, textarea") as HTMLInputElement)?.focus?.(
      {
        preventScroll: true,
      },
    );
    setTimeout(() => {
      el?.classList.remove("ping-border");
    }, 1000);
  };

  useEffect(() => {
    setTimeout(() => {
      modalData?.metaData?.highlightCsv && scrollToElement("startWithCard_csv");
    }, 300);
  }, [isOpenModal]);

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "get-started"}
      motionPreset="scale"
      size="6xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />

      <ModalContent className="rounded-[20px]">
        <div
          className="h-[18px]"
          style={{
            background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%) ",
            boxShadow: "0px 6px 58px 0px rgba(121, 145, 173, 0.20)",
            borderRadius: "6px 6px 0 0",
          }}
        />
        <ModalBody className="">
          <div className="flex items-center justify-between p-10">
            <h3 className="text-[24px] font-bold">
              Enrich Your <span className="text-textPurple">Leads</span>
            </h3>
            <div className="flex items-center gap-2">
              <Menu autoSelect={false}>
                <MenuButton
                  as={Button}
                  variant={"outline"}
                  leftIcon={<Icons.OutlineAddIcon className="text-xl" />}
                  rightIcon={<Icons.ChevronDownIcon />}
                  className="btn-primary-outline flex min-w-fit cursor-pointer items-center justify-center gap-3 rounded p-2 px-5 text-[16px] font-[500] text-white"
                >
                  <span>{isPending ? "Creating..." : "Create New"}</span>
                </MenuButton>
                <MenuList>
                  {TableOptions.map((item) => (
                    <MenuItem
                      key={item.name}
                      onClick={() => {
                        if (item.name === "Auto Pilot Workflow") {
                          navigate({ to: "/auto-pilot" });
                          handleModalClose();
                        } else {
                          handleCreateBlankTable(item.payload);
                        }
                      }}
                    >
                      <item.icon />
                      {item.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <button
                className="btn-primary-outline flex min-w-fit cursor-pointer items-center justify-center gap-3 rounded p-2 px-5 text-[16px] font-[500] text-white"
                onClick={() =>
                  updateState({
                    isOpenModal: true,
                    modalData: { modalType: "use-template" },
                  })
                }
              >
                <Icons.TemplateIcon className="text-xl" />
                <span>Use templates</span>
              </button>
            </div>
          </div>
          <div className="custom-scrollbar max-h-[73vh] space-y-9 overflow-y-auto px-10 pb-10">
            {allActions.map((action, index) => (
              <div
                key={index}
                className={`startwith-action-container-${index + 1}`}
              >
                {action?.name && (
                  <h2 className="pb-2.5 text-[14px] font-medium leading-[18px] text-[#7E889F]">
                    {action?.name}
                  </h2>
                )}
                <div
                  className="grid gap-5"
                  style={{
                    gridTemplateColumns: `repeat(${action?.maxCards}, minmax(0, 1fr))`,
                  }}
                >
                  {action?.actions?.map((card, index) => (
                    <>
                      {(!card.isForPro || (card.isForPro && isPro)) && (
                        <div
                          key={index}
                          id={`startWithCard_${card.actionType}`}
                          onClick={() => handleCardClick(card.actionType)}
                        >
                          <div
                            className={`flex w-full cursor-pointer items-start gap-[12px] rounded-[8px] bg-white p-[20px] shadow-lg`}
                          >
                            <img
                              src={card.icon}
                              alt=""
                              width={30}
                              height={30}
                            />
                            <div>
                              <p
                                className={`text-left text-[16px] font-medium text-black`}
                              >
                                {card?.title}
                              </p>
                              <p
                                className={`text-left text-[14px] font-[400] text-[#7A7A7A] ${
                                  action?.maxCards === 2 ? "pb-3" : ""
                                }`}
                              >
                                <span>{card?.description}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            ))}
            <StarterList />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GetStartedModal;
