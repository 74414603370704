import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

import userService from "@/services/user.service";
import {
  CreditDetails,
  JobChangeTrackerDetails,
  ResponseData,
  SubscriptionDetails,
  UserData,
  UserProfileData,
} from "@/types/user.types";

type State = ResponseData & {
  isBetaEnabled: boolean;
  currentUserProfile: UserProfileData;
  creditDetails: CreditDetails;
  subscriptionDetails: SubscriptionDetails;
};

type Actions = {
  isUpgradeBannerShown(): boolean;
  hideUpgradeBanner(): void;
  updateState: (payload: Partial<State>) => void;
  setCurrentUser: (payload: UserData) => void;
  setCurrentUserProfile: (payload: UserProfileData) => void;
  setIsBetaEnabled: (payload: boolean) => void;
  setCreditDetails: (payload: CreditDetails) => void;
  refreshCurrentUser: () => void;
  setJobChangeTracker: (payload: JobChangeTrackerDetails) => void;
};

export const userStore = create<State & Actions>((set, get) => ({
  currentUser: {
    _id: "",
    createdAt: "",
    Email: "",
    MagicLink: "",
    role: "",
    MagicLinkExpired: false,
  },
  isBetaEnabled: false,
  creditDetails: {
    creditLeft: 0,
    creditUsed: 0,
    planType: "Free",
    _id: "",
  },
  currentUserProfile: {
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyURL: "",
    companyName: "",
    companyTarget: "",
    companyDescription: "",
    callToAction: "",
  },
  jobChangeTracker: {
    isAutoRefreshEnabled: false,
    selectedSlackChannel: {
      label: "",
      value: "",
    },
    slackChannels: [],
  },
  findPeoplePreference: {
    isAutoSearchEnabled: false,
    appliedFilters: {},
  },

  subscriptionDetails: {
    billingEmail: "",
    currentPlan: {
      credits: "",
      planType: "",
      priceId: "",
    },
    invoices: [],
    nextCycleAmount: 0,
    nextCycleDate: 0,
    scheduledActions: [],
  },

  isUpgradeBannerShown() {
    const cached = localStorage.getItem("hideUpgradeModal");
    if (cached) {
      return false;
    }
    const creditDetails = get().creditDetails;
    return creditDetails?.planType?.toLocaleLowerCase() === "free";
  },
  hideUpgradeBanner: () => {
    localStorage.setItem("hideUpgradeModal", "true");
    set((state) => ({ ...state }));
  },
  updateState: (payload) => set(() => payload),
  setCurrentUser: (payload: UserData) =>
    set((state) => ({ ...state, currentUser: payload })),
  setCurrentUserProfile: (payload: UserProfileData) =>
    set((state) => ({ ...state, currentUserProfile: payload })),
  setCreditDetails: (payload: CreditDetails) =>
    set((state) => ({ ...state, creditDetails: payload })),
  setIsBetaEnabled: (payload: boolean) =>
    set((state) => ({ ...state, isBetaEnabled: payload })),
  setJobChangeTracker: (payload: JobChangeTrackerDetails) =>
    set((state) => ({ ...state, jobChangeTracker: payload })),

  refreshCurrentUser: async () => {
    const response = await userService.getUser();
    if (response) {
      set((state) => ({
        ...state,
        currentUser: response,
        currentUserProfile: response.profile,
        creditDetails: {
          creditLeft: response.creditLeft,
          creditUsed: response.creditUsed,
          planType: response.planType,
        },
      }));
    }
  },
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("User", userStore);
}
