import { useEffect, useState } from "react";
import { Box, Switch } from "@chakra-ui/react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";

import { useTableStore } from "@/stores/table.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { InsightColumns, RESPONSE_COLUMNS } from "./constant";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { REGEX } from "../../constants";
import { useEnrichStore } from "@/stores/enrich.store";
import { EnrichmentMetaData } from "@/types/table.types";
import ConditionalFormula from "../../Common/ConditionalFormula";
import Icons from "@/components/Icons";

interface EnrichmentDataType extends EnrichmentMetaData {
  extraColumns?: string[];
}

const LinkedInProfileDetails = () => {
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [responseColumns, setResponseColumns] = useState(
    () => RESPONSE_COLUMNS,
  );
  const [insightColumns, setInsightColumns] = useState(() => InsightColumns);
  const handleSwitch = (id: string) => {
    setResponseColumns((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item,
      ),
    );
    setInsightColumns((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item,
      ),
    );
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        if (enrichmentData.extraColumns) {
          setResponseColumns((prevState) =>
            prevState.map((item) => ({
              ...item,
              isChecked:
                enrichmentData?.extraColumns?.includes(item.label) ?? false,
            })),
          );
          setInsightColumns((prevState) =>
            prevState.map((item) => ({
              ...item,
              isChecked:
                enrichmentData?.extraColumns?.includes(item.label) ?? false,
            })),
          );
        }
      }
    } else {
      const initialLinkedInColumn = getInitialColumn(REGEX.LINKEDINPROFILE);
      if (initialLinkedInColumn) {
        setSelectedOption({
          key: initialLinkedInColumn.name,
          keyId: initialLinkedInColumn._id,
          iconType: initialLinkedInColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns]);

  useEffect(() => {
    setResponseColumns((prevState) =>
      prevState.map((item) =>
        item.id === selectedEnrichments?.id
          ? { ...item, isChecked: true }
          : item,
      ),
    );
  }, [selectedEnrichments]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={"Get the Person's Profile Details by LinkedIn URL"}
          link={""}
        />
        <p className="pb-1 text-base font-semibold">LinkedIn Url</p>

        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <div className="">
          <div className="mt-4 flex flex-col gap-[8px] pb-4">
            <div className="mb-2 flex items-center justify-between">
              <p className="text-base font-semibold">
                Add data as columns to your table
              </p>
              <p className="text-grey-400 text-[14px] text-[#676d7e]">
                {responseColumns.filter((item) => item.isChecked).length +
                  insightColumns.filter((item) => item.isChecked).length}{" "}
                columns selected
              </p>
            </div>
            {responseColumns.map(({ id, label, isChecked }) => (
              <div
                key={id}
                className="flex w-full items-center justify-between rounded-[6px] bg-[#FAFAFB] px-4 py-2"
              >
                <p className="text-[16px] font-[500]">{label}</p>
                <Switch
                  size={"md"}
                  isChecked={isChecked}
                  id={id}
                  onChange={() => handleSwitch(id)}
                />
              </div>
            ))}
            <Box className="mt-4 flex w-full flex-row items-center justify-between pb-4 pr-4">
              <p className="text-[14px] font-semibold">
                Add Person Insights Columns
              </p>
              <div
                className={`
                  ${insightColumns.filter((item) => item.isChecked).length ? "" : "opacity-50"}
                  flex items-center justify-center gap-[4px] rounded-[4px]
                   bg-[#e6fce9] px-[4px] py-[2px] text-[#2bbf71]`}
              >
                <span className="text-[12px] font-[500] uppercase">+1</span>
                <Icons.CoinsIcon className="text-sm text-[#2bbf71]" />
              </div>
            </Box>
            {insightColumns.map(({ id, label, isChecked }) => (
              <div
                key={id}
                className="flex w-full items-center justify-between rounded-[6px] bg-[#FAFAFB] px-4 py-2"
              >
                <p className="text-[16px] font-[500]">{label}</p>
                <Switch
                  size={"md"}
                  isChecked={isChecked}
                  id={id}
                  onChange={() => handleSwitch(id)}
                />
              </div>
            ))}
          </div>
        </div>

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
          extraColumns: responseColumns
            .filter((item) => item.isChecked)
            .map((item) => item.label)
            .concat(
              insightColumns
                .filter((item) => item.isChecked)
                .map((item) => item.label),
            ),
        }}
      />
    </>
  );
};

export default LinkedInProfileDetails;
