import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Spinner,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import type { ICellRendererParams } from "ag-grid-community";
import { useCallback, useState } from "react";

import EmailIcon from "@/assets/svgs/people-search-email.svg";
import enrichment from "@/services/enrichment.service";
import { SingleEnrichmentPayload } from "@/types/enrichment.types";
import Icons from "../Icons";

export const WorkEmailRenderer = (props: ICellRendererParams) => {
  const [isOpen, setIsOpen] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [textCopied, setTextCopied] = useState(false);
  const { mutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: SingleEnrichmentPayload }) =>
      enrichment.getSingleWorkEmail("findWorkEmail", payload),
  });

  const copyEmail = () => {
    const rowData = props.data;
    if (emailRegex.test(rowData.workEmail)) {
      navigator.clipboard.writeText(rowData.workEmail);
      setTextCopied(true);
      setTimeout(() => {
        setTextCopied(false);
      }, 1000);
      return;
    }
  };

  const handleAccessEmail = useCallback(
    async (props: ICellRendererParams) => {
      const rowData = props.data;
      const rowNode = props.api.getRowNode(rowData.id);

      if (
        rowData.workEmail === "No Email Found" ||
        rowData.workEmail.startsWith("Loading")
      )
        return;
      rowData.workEmail = "Loading...";
      rowNode?.setDataValue(rowData.id, rowData);

      const payload = {
        jobName: "FIND_SINGLE_WORK_EMAIL",
        payload: {
          providers: [
            "ProspeoProfile",
            "ProspeoLinkedinEmail",
            "Datagma",
            "LeadMagic",
          ],
          fullName: rowData.name,
          companyName: rowData.companyWebsite,
          linkedinUrl: rowData.linkedInUrl,
          rowId: rowData.id,
        },
        uniqueSocketId: rowData.quickAction.uniqueSocketId,
      };
      const response = await mutateAsync({ payload });
      if (!response.success) {
        rowData.workEmail = "Access Email";
      }
    },
    [mutateAsync],
  );

  const getCellValue = useCallback((cellValue: string) => {
    if (cellValue.startsWith("Loading")) {
      return (
        <span className="flex items-center gap-2 text-[14px] font-[500]">
          <Spinner size="xs" />
          <span>Loading...</span>
        </span>
      );
    }
    return <span className="text-[14px] font-[500]">{cellValue}</span>;
  }, []);

  const cellValue = props.data.workEmail || "";
  const isLoading = cellValue.startsWith("Loading");

  return (
    <div className="flex w-fit">
      {emailRegex.test(cellValue) ? (
        <Menu isOpen={isOpen} closeOnBlur>
          <MenuButton
            as={Button}
            className="!h-[35px] !w-fit !text-ellipsis !bg-[#fff] !text-[#5024DC] hover:!bg-[#F5F3FF] hover:!shadow-md"
            variant={"outline"}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="!flex w-full !items-center gap-2 text-ellipsis">
              <img src={EmailIcon} />
              <span className="inline-block w-full text-ellipsis text-[14px] font-[600]">
                {cellValue}
              </span>
              <Icons.ArrowDownIcon />
            </div>
          </MenuButton>
          <Portal>
            <MenuList>
              <div className="px-[20px] py-[16px]">
                {props.data.quickAction.email_status === "verified" ? (
                  <div className="mb-1 flex w-fit items-center gap-1 rounded-[12px] bg-[#C8FDD4] px-[8px] py-[2px] text-[12px] font-[500] text-[#0b733f]">
                    <Icons.CheckIcon size={14} />
                    <span>Verified</span>
                  </div>
                ) : (
                  <div className="mb-1 flex w-fit items-center gap-1 rounded-[12px] bg-[#F7C6C7] px-[8px] py-[2px] text-[12px] font-[500] text-[#E53935]">
                    <Icons.CloseIcon size={14} />
                    <span>Not Verified</span>
                  </div>
                )}
                <div className="flex items-center gap-2">
                  <span className="text-[14px] font-[600]">
                    {textCopied ? "Copied" : cellValue}
                  </span>
                  <Icons.CopyIcon
                    className="cursor-pointer"
                    onClick={copyEmail}
                  />
                </div>
              </div>
            </MenuList>
          </Portal>
        </Menu>
      ) : (
        <button
          className={`flex !h-[35px] w-fit items-center gap-2 rounded-[8px] border border-[#D0D4DC] !px-[16px] !py-[8px] text-base font-normal text-dark ${cellValue === "No Email Found" ? "cursor-not-allowed" : "cursor-pointer"} ${isLoading ? "!cursor-not-allowed" : ""}`}
          onClick={() =>
            props?.data && !isLoading ? handleAccessEmail(props) : null
          }
        >
          <img src={EmailIcon} />
          <span className="text-[14px] font-[500]">
            {cellValue ? getCellValue(cellValue) : "Access Email"}
          </span>
        </button>
      )}
    </div>
  );
};
